// extracted by mini-css-extract-plugin
export var aboutMe = "talk-module--about-me--X6hUQ";
export var circle = "talk-module--circle--hENhg";
export var comment = "talk-module--comment--jh+Gf";
export var exampleGrid = "talk-module--example-grid--3CYCk";
export var generatingDiagram = "talk-module--generating-diagram--HoZ5H";
export var generativeModelingSplit = "talk-module--generative-modeling-split--XHx8Y";
export var mcmcDiagram = "talk-module--mcmc-diagram--h4RFf";
export var operation = "talk-module--operation--7ckde";
export var operationsBlock = "talk-module--operations-block--wgnNH";
export var uncertainQuantities = "talk-module--uncertain-quantities--s1D3H";