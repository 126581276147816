import GATSBY_COMPILED_MDX from "/home/runner/work/personal-website/personal-website/content/presentations/generative-inference/talk.mdx";
import React from 'react';
import {Deck} from '@presentations';
import {updateClass} from '../utils/themes';
import useKeyboardShortcuts from '../hooks/use-keyboard-shortcuts';
import NavButton from '../components/nav-button';
function Presentation(props) {
  useKeyboardShortcuts();
  return React.createElement("div", {
    className: "w-screen h-screen relative"
  }, React.createElement(Deck, props), React.createElement(NavButton, null));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Presentation, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export function Head(props) {
  React.useEffect(updateClass, []);
  return React.createElement(React.Fragment, null, React.createElement("title", null, props.data.presentation.title + " | rodent.club"));
}
var pageQuery = "1433462960";
